/** @jsx jsx */
import { graphql } from "gatsby";
import { Box, Container, Heading, Image, jsx } from "theme-ui";
import CertificationList from "../components/CertificationList";
import HexagonRowList from "../components/HexagonRowList";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const About = ({ data }) => {
  const HEXAGON_ROW_LIST_DATA = [
    {
      imageAlt: "Image of ribbon.",
      imageURL: data.HexagonRibbon.publicURL,
      headingText: "Experience",
      content: (
        <p>
          Over 30 years of experience in residential, commercial and specialized
          roofing service
        </p>
      ),
    },
    {
      imageAlt: "Image of dollar sign.",
      imageURL: data.HexagonDollarSign.publicURL,
      headingText: "Value",
      content: (
        <p>
          Providing the best possible roofing products and installation at the
          right price for every customer
        </p>
      ),
    },
    {
      imageAlt: "Image of handshake.",
      imageURL: data.HexagonHandshake.publicURL,
      headingText: "Customer service",
      content: (
        <p>
          Ensuring your satisfaction from service request to project completion
        </p>
      ),
    },
  ];

  return (
    <Layout
      contactBackground={data.ContactBackground.childImageSharp.gatsbyImageData}
      headerBannerText="About legacy repair"
      headerBgImage={data.HeroImage.childImageSharp.gatsbyImageData}
      headerFancyBanner={false}
      headerSmallHero={true}
    >
      <Seo title="About Us" />

      <Container as="section" sx={{ my: [8, null, 11] }}>
        <Image
          src={data.WhoWeAre.publicURL}
          alt="Legacy Repair Crew"
          sx={{
            maxHeight: "500px",
            width: "100%",
            objectFit: "cover",
          }}
        />

        <Box
          sx={{
            my: [3, null, 6],
          }}
        >
          <Heading as="h1" variant="styles.fancyUnderline">
            Who We Are
          </Heading>
        </Box>

        <p>
          Legacy Repairs is a veteran-owned company of experienced journeyman
          roofers dedicated to making Arizona roofs last longer and perform
          better. While every roof has to be replaced at some point, we believe
          that there should be more roofers who specialize in maintenance.
        </p>

        <p>
          Our company was founded by roofers with over 30 years of experience in
          Phoenix and across central and northern Arizona, across all roof types
          and applications. Our first priority is exceeding customer
          expectations and building a legacy of integrity, value, service and
          commitment to every customer.
        </p>

        <ul
          sx={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            listStyleType: "none",
            mb: 0,
            mt: [6, null, 8],
            p: 0,
            li: {
              flex: ["0 0 100%", "0 0 48%"],
              textAlign: "center",
              "&:not(:first-of-type)": {
                mt: [4, 0],
              },
            },
          }}
        >
          <li>
            <Image
              alt="Legacy Repair Logo"
              src={data.SimpleLogo.publicURL}
              sx={{
                verticalAlign: "middle",
                width: "100%",
              }}
            />
          </li>
        </ul>
      </Container>

      <HexagonRowList
        bannerText="our legacy"
        listItemsArr={HEXAGON_ROW_LIST_DATA}
      />

      <CertificationList
        backgroundImageDesktop={data.BottomDots}
        backgroundImageMobile={data.BottomDotsMobile}
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    HeroImage: file(relativePath: { eq: "about-hero.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    WhoWeAre: file(relativePath: { eq: "legacy-repair-crew.jpg" }) {
      id
      publicURL
    }
    SimpleLogo: file(relativePath: { eq: "legacy-repair-logo-simple.svg" }) {
      id
      publicURL
    }
    LegacyRoofingLogo: file(relativePath: { eq: "legacy-roofing-logo.svg" }) {
      id
      publicURL
    }
    ContactBackground: file(relativePath: { eq: "footer-bg.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    BottomDots: file(relativePath: { eq: "bottom-dots.svg" }) {
      id
      publicURL
    }
    BottomDotsMobile: file(relativePath: { eq: "bottom-dots-mobile.svg" }) {
      id
      publicURL
    }
    HexagonHandshake: file(
      relativePath: { eq: "hexagon-silver-handshake.svg" }
    ) {
      id
      publicURL
    }
    HexagonDollarSign: file(
      relativePath: { eq: "hexagon-silver-dollar-sign.svg" }
    ) {
      id
      publicURL
    }
    HexagonRibbon: file(relativePath: { eq: "hexagon-silver-ribbon.svg" }) {
      id
      publicURL
    }
  }
`;

export default About;
