/** @jsx jsx */
import PropTypes from "prop-types";
import { Box, Heading, jsx } from "theme-ui";
import LinkButton from "../components/LinkButton";

const HexagonCard = ({
  className,
  content,
  headingText,
  imageAlt,
  imageURL,
  linkEnabled,
  linkURL,
  textColor,
  ...props
}) => {
  return (
    <Box
      {...props}
      as="article"
      className={className}
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "flex-start",
        textAlign: "center",
      }}
    >
      <img
        alt={imageAlt}
        src={imageURL}
        sx={{
          mb: 4,
          verticalAlign: "middle",
        }}
      />

      <Heading
        as="h1"
        variant="styles.h3"
        sx={{
          color: textColor,
        }}
      >
        {headingText}
      </Heading>

      <Box
        sx={{
          color: textColor,
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {content}
      </Box>

      {linkEnabled && (
        <LinkButton to={linkURL} variant="secondary">
          Learn More
        </LinkButton>
      )}
    </Box>
  );
};

HexagonCard.propTypes = {
  className: PropTypes.string,
  content: PropTypes.node,
  headingText: PropTypes.string,
  imageAlt: PropTypes.string,
  imageURL: PropTypes.string,
  linkEnabled: PropTypes.bool,
  linkURL: PropTypes.string,
  textColor: PropTypes.string,
};

HexagonCard.defaultProps = {
  linkEnabled: true,
  textColor: "#000",
};

export default HexagonCard;
