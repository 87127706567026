/** @jsx jsx */
import PropTypes from "prop-types";
import { Box, Container, Flex, Heading, Paragraph, jsx } from "theme-ui";
import AllAmericanHomeLogo from "../images/all-american-home-logo.png";
import AZRoofingContractorsAssociationLogo from "../images/az-roofing-contractors-association-logo.png";
import EthicalArizonaLogo from "../images/ethical-arizona-logo.png";
import GacoFlexLogo from "../images/gaco-flex-logo.png";
import HabitatForHumanityLogo from "../images/habitat-for-humanity-logo.png";
import MasterEliteLogo from "../images/master-elite-logo.png";
import RegistrarOfContractorsLogo from "../images/registrar-of-contractors-logo.png";
import SchoaLogo from "../images/schoa-logo.png";
import TamkoProLogo from "../images/tamko-pro-logo.png";

/**
 * i would have used StaticImage here but you cannot loop pass
 * props for the src of staticImage. see here
 * https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#restrictions-on-using-staticimage
 */

const LIST_ITEMS = [
  {
    linkURL: "https://www.habitat.org/",
    imageURL: HabitatForHumanityLogo,
    imageAlt: "Habitat For Humanity Logo",
  },
  {
    linkURL: null,
    imageURL: GacoFlexLogo,
    imageAlt: "Gaco Flex Logo",
  },
  // {
  //   linkURL: null,
  //   imageURL: PrescottChamberLogo,
  //   imageAlt: "PrescottChamberLogo",
  // },
  {
    linkURL: "https://www.tamkopro.com/aspx/login.aspx",
    imageURL: TamkoProLogo,
    imageAlt: "Tamko Pro Logo",
  },
  {
    linkURL: "https://suncityhoa.org/",
    imageURL: SchoaLogo,
    imageAlt: "Schoa Logo",
  },
  {
    linkURL: null,
    imageURL: AZRoofingContractorsAssociationLogo,
    imageAlt: "Arizona Roofing Contractors Association Logo",
  },
  {
    linkURL: null,
    imageURL: AllAmericanHomeLogo,
    imageAlt: "All American Home Logo",
  },
  {
    linkURL: null,
    imageURL: MasterEliteLogo,
    imageAlt: "Master Elite Logo",
  },
  {
    linkURL: "http://www.ethicalaz.com/s/faq/",
    imageURL: EthicalArizonaLogo,
    imageAlt: "Ethical Arizona Logo",
  },
  {
    linkURL: "https://roc.az.gov/",
    imageURL: RegistrarOfContractorsLogo,
    imageAlt: "Registrar Of Contractors Logo",
  },
];

const CertificationList = ({
  className,
  backgroundImageMobile,
  backgroundImageDesktop,
}) => {
  return (
    <Box
      as="section"
      className={className}
      sx={{
        mt: [8, null, 12],
        pb: [8, null, 14],
        backgroundImage:
          typeof backgroundImageDesktop !== "undefined" &&
          typeof backgroundImageMobile !== "undefined"
            ? [
                `url(${backgroundImageMobile.publicURL})`,
                null,
                `url(${backgroundImageDesktop.publicURL})`,
              ]
            : "none",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        backgroundSize: "100%",
      }}
    >
      <Container>
        <Box sx={{ textAlign: "center", mb: [5, null, 8] }}>
          <Heading as="h1" variant="styles.fancyUnderline">
            Certifications
          </Heading>

          <Paragraph>ROC# 339384</Paragraph>
        </Box>

        <Flex
          as="ul"
          sx={{
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: ["center", null, "space-evenly"],
            listStyleType: "none",
            mx: 0,
            my: 4,
            p: 0,
            li: {
              flex: ["0 0 auto", null, "0 0 20%"],
              textAlign: "center",
              ":nth-of-type(n + 3)": {
                mt: [5, null, 0],
              },
              ":nth-of-type(n + 7)": {
                mt: [5, null, 6],
              },
            },
          }}
        >
          {LIST_ITEMS.map((cert, index) => (
            <li key={index}>
              {cert.linkURL === null ? (
                <img
                  src={cert.imageURL}
                  alt={cert.imageAlt}
                  sx={{
                    height: ["45px", null, "80px"],
                    width: ["135px", null, "210px"],
                    maxWidth: "100%",
                    objectFit: "contain",
                    verticalAlign: "middle",
                  }}
                />
              ) : (
                <a
                  href={cert.linkURL}
                  target="_blank"
                  rel="noreferrer"
                  sx={{ display: "inline-block" }}
                >
                  <img
                    src={cert.imageURL}
                    alt={cert.imageAlt}
                    loading="lazy"
                    sx={{
                      height: ["45px", null, "80px"],
                      width: ["135px", null, "210px"],

                      objectFit: "contain",
                      verticalAlign: "middle",
                    }}
                  />
                </a>
              )}
            </li>
          ))}
        </Flex>
      </Container>
    </Box>
  );
};

CertificationList.propTypes = {
  className: PropTypes.string,
};

export default CertificationList;
