/** @jsx jsx */
import PropTypes from "prop-types";
import { Box, Container, jsx } from "theme-ui";
import HexagonCard from "../components/HexagonCard";
import SkewedText from "../components/SkewedText";

const HexagonRowList = ({
  bannerText,
  children,
  className,
  listItemsArr,
  ...props
}) => {
  return (
    <Box
      {...props}
      className={className}
      sx={{
        backgroundColor: "darkBlue",
        pb: [9, null, 10],
        position: "relative",
        pt: [9, null, 14],
        mt: ["12px", null, "34px"],
      }}
    >
      <Container>
        <ul
          sx={{
            alignItems: "flex-start",
            display: "grid",
            gridTemplateColumns: ["100%", "50%", "repeat(3, 30%)"],
            justifyContent: ["center", null, "space-between"],
            listStyleType: "none",
            m: 0,
            p: 0,
            "li:not(:first-of-type)": {
              mt: [8, null, 0],
            },
          }}
        >
          {listItemsArr.map((listItem, index) => (
            <li key={index}>
              <HexagonCard
                content={listItem.content}
                headingText={listItem.headingText}
                imageAlt={listItem.imageAlt}
                imageURL={listItem.imageURL}
                linkEnabled={false}
                textColor="#fff"
              />
            </li>
          ))}
        </ul>
      </Container>

      <SkewedText
        as="h1"
        text={bannerText}
        sx={{
          left: 0,
          position: "absolute",
          px: [6, null, 15],
          top: 0,
        }}
      />
    </Box>
  );
};

HexagonRowList.propTypes = {
  bannerText: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  listItemsArr: PropTypes.array,
};

export default HexagonRowList;
